// css
import '../../scss/pages/404.scss';

// utils
import foundation from '../_utils/foundation';
foundation();

document.addEventListener('DOMContentLoaded', () => {});

window.addEventListener('load', () => {});
